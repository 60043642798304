import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const ResumePageTemplate = ({ title, content, contentComponent, image }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section has-background-primary">
      <div className="container">
        <div className="content">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light line-after">
              <span>{title}</span>
            </h1>
            <article className="tile is-child">
              <PageContent className="content" content={content} />
            </article>
          </div>
        </div>
    </section>
  );
};

ResumePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const ResumePage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ResumePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        image={post.frontmatter.image}
      />
    </Layout>
  );
};

ResumePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ResumePage;

export const ResumePageQuery = graphql`
  query ResumePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;